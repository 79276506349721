import {LogManager, inject, bindable, noView, observable} from 'aurelia-framework';
import {ComponentAttached, ComponentDetached, TemplatingEngine} from 'aurelia-templating';
import {Logger} from 'aurelia-logging';
import {DOM} from 'aurelia-pal';
import {HttpClient, json} from "aurelia-fetch-client";
import {Option} from "./Select2CustomElement";

class Export
{
    public dateBegin:string = '';
    public dateEnd:string = '';
}

@noView()
@inject(DOM.Element, TemplatingEngine)
class ExcelFormCustomElement implements ComponentAttached, ComponentDetached
{
    private logger:Logger = LogManager.getLogger('ExcelFormCustomElement');
    private httpClient:HttpClient;
    private templatingEngine: TemplatingEngine;

    private element:Element;
    private formElement:HTMLFormElement;
    @bindable({attribute: 'form-name'}) public formName:string;
    public isLoading:boolean = false;
    public export:Export = new Export();

    private excelUrl:Location;
    private wsUrl:string;
    private deleteFileUrl:string;

    public constructor(element:Element, templatingEngine:TemplatingEngine)
    {
        this.logger.debug('Construct');
        this.element = element;
        this.httpClient = new HttpClient();
        this.httpClient.configure(config => {
            config
                .useStandardConfiguration()
                .withDefaults({
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
            ;
        });
        this.templatingEngine = templatingEngine;
    }

    public attached()
    {
        this.logger.debug('Attached');
        this.formElement = <HTMLFormElement>document.getElementById(this.formName);
        this.templatingEngine.enhance({
            element: this.formElement,
            bindingContext: this
        });
    }

    public detached()
    {
        this.logger.debug('Detached');
    }

    public onSubmit()
    {
        this.logger.debug('onSubmit', this.export);
        let button = <HTMLButtonElement>document.getElementById('export-form-button');
        button.innerHTML = 'Génération du fichier excel...';
        if (button.className.indexOf('b4') !== -1) {
            button.className = button.className.replace('b4', 'b10');
        } else {
            button.className = button.className.replace('b11', 'b10');
        }
        let name:string = this.formName;
        let body:any = {
            User: Object.assign({}, this.export),
            form_name: name
        };

        this.isLoading = true;
        this.httpClient.fetch(this.formElement.action, {
            method: 'post',
            body: json(body)
        })
            .then(response => response.text())
            .then((value:any) => {
                let data:any  = JSON.parse(value);

                this.wsUrl = data['wsUrl'];
                this.excelUrl= data['excelUrl'];
                this.deleteFileUrl = data['deleteFileUrl'];
                this.callWs(button);
                this.isLoading = false;
                this.isLoading = false;
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    };

    public onSecondSubmit()
    {
        this.logger.debug('onSecondSubmit', this.export);
        let button = <HTMLButtonElement>document.getElementById('export-old-button');
        button.innerHTML = 'Génération du fichier excel...';
        if (button.className.indexOf('b4') !== -1) {
            button.className = button.className.replace('b4', 'b10');
        } else {
            button.className = button.className.replace('b11', 'b10');
        }
        let name:string = this.formName;
        //_csrf: (<any>document.getElementsByName('_csrf'))[0].value,
        let body:any = {
            form_name: name
        };

        this.isLoading = true;
        this.httpClient.fetch(this.formElement.action, {
            method: 'post',
            body: json(body)
        })
            .then(response => response.text())
            .then((value:any) => {
                let data:any  = JSON.parse(value);

                this.wsUrl = data['wsUrl'];
                this.excelUrl= data['excelUrl'];
                this.deleteFileUrl = data['deleteFileUrl'];
                this.callWs(button);
                this.isLoading = false;
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    };

    public callWs(button:HTMLButtonElement)
    {
        this.httpClient.fetch(this.wsUrl, {
            method: 'get',
        })
            .then(response => response.text())
            .then((value:any) => {
                if (value == "false") {
                    setTimeout(() => {
                        this.callWs(button);
                    }, 1000);
                } else {
                    button.innerHTML = 'Fichier généré';
                    button.className = button.className.replace('b10', 'b11');
                    window.location = this.excelUrl;
                }
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    }

}

export {ExcelFormCustomElement}