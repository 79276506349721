import './scss/main.scss';

import {bootstrap} from "aurelia-bootstrapper";
import { Aurelia, PLATFORM } from 'aurelia-framework';
import { ComponentRegistry } from 'aurelia-web-components';

declare var PRODUCTION:boolean;

/**
 * Aurelia bootstrapping
 *
 * @author Philippe Gaultier <pgaultier@ibitux.com>
 * @copyright 2010-2017 Ibitux
 * @license http://www.ibitux.com/license license
 * @version XXX
 * @link http://www.ibitux.com
 * @since XXX
 */
bootstrap((aurelia: Aurelia) => {
    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('components/index'))
    ;
    if (PRODUCTION == false) {
        aurelia.use.developmentLogging();
    }
    aurelia.start().then(() => {
        // let registry:ComponentRegistry = aurelia.container.get(ComponentRegistry);
        // registry.registerAllGlobalElements();
        return aurelia.enhance(document);
    });
});