let $ = require('jquery');

class Payment {

    private dynamicForm:JQuery;

    public constructor()
    {
        this.init();
    }

    private init()
    {
        this.bindForm();
    }

    private bindForm()
    {
        this.dynamicForm = $('.dynamic-payment');
        this.dynamicForm.data('ready', false);
        this.dynamicForm.on('submit', (evt:Event) => {
            let $currentForm:HTMLFormElement = $(evt.currentTarget);
            if ($currentForm.data('ready') === false) {
                evt.preventDefault();
                let $amountInput: JQuery = $currentForm.parent().parent().find('input[name=montant]');
                let amount: any = $amountInput.val();
                let cotisationId: any = $currentForm.data('cotisation');
                let request: any = $currentForm.data('url');
                let $dataInput: JQuery = $currentForm.find('input[name=DATA]');

                if ($amountInput.length === 0) {
                    amount = $currentForm.data('amount');
                } else if (amount < 1) {
                    alert('Montant minimum d\'un euro');
                }
                $.ajax({
                    method: 'POST',
                    url: request,
                    data: JSON.stringify({
                        cotisationId: cotisationId,
                        amount: amount
                    }),
                    dataType: 'json',
                    contentType: 'application/json'
                })
                    .done((data: any, textStatus: string, jqXHR: JQueryXHR) => {
                        if (data !== null) {
                            $dataInput.val(data);
                            $currentForm.data('ready', true);
                            $currentForm.submit();
                        }
                    })
                    .fail((jqXHR: JQueryXHR, textStatus: string, errorThrown: any) => {
                        $currentForm.data('ready', false);
                    });
            }
        });

    }
}

export { Payment }