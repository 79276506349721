import {LogManager, inject, observable, bindable, BindingEngine, ICollectionObserverSplice} from 'aurelia-framework';
import {ComponentAttached, ComponentDetached} from 'aurelia-templating';
import {Logger} from 'aurelia-logging';
import {DOM} from 'aurelia-pal';

import * as $ from 'jquery';
import 'select2';

interface Option
{
    value:string|number,
    label:string
}

@inject(DOM.Element, BindingEngine)
class Select2CustomElement implements ComponentAttached, ComponentDetached
{
    private logger: Logger = LogManager.getLogger('Select2CustomElement');
    private bindingEngine:BindingEngine;
    private element:Element;

    private selectElement:HTMLSelectElement;
    @bindable() public selected:any;
    @bindable() public options:Option[] = [];
    @bindable() public generate= true;
    @bindable() public name:string;
    @bindable({attribute: 'idi'}) public idi:string;
    @bindable({attribute: 'baseurl'}) public baseurl:string;
    @bindable() public search:string;
    @bindable({attribute: 'is-multiple'}) public isMultiple:boolean = false;
    @bindable() public placeholder:string;

    public constructor(element:Element, bindingEngine:BindingEngine)
    {
        this.logger.debug('Construct');
        this.element = element;
        this.bindingEngine = bindingEngine;
    }

    public attached()
    {
        this.logger.debug('Attached');
        this.selectElement = <HTMLSelectElement>document.getElementById(this.name);
        let collection:any = this.selectElement.children;
        if (this.generate === true) {
            for (let i = 0; i < collection.length; i++) {
                let option:Option = {
                    value:collection[i].value,
                    label:collection[i].innerText
                };
                if (collection[i].selected === true) {
                    this.selected = option.value;
                }
                this.options.push(option);
            }
        }
        this.selectElement.classList.add('hidden');

        let el = $(this.element).find('select');
        let sel;
        if (this.search === 'false') {
            sel = el.select2({
                placeholder: this.placeholder,
                minimumResultsForSearch: -1,
                "language": {
                    "noResults": function(){
                        return "Aucun résultat trouvé";
                    }
                },
                escapeMarkup: function (markup) {
                    return markup;
                }
            });
        } else {
            sel = el.select2({
                placeholder: this.placeholder,
                "language": {
                    "noResults": function(){
                        return "Aucun résultat trouvé";
                    }
                },
                escapeMarkup: function (markup) {
                    return markup;
                }
            });
        }

        sel.val(this.selected).trigger('change');
        // on any change, propagate it to underlying select to trigger two-way bind
        sel.on('change', (event:any) => {
            // don't propagate endlessly
            // see: http://stackoverflow.com/a/34121891/4354884
            if (event.originalEvent) { return; }
            // dispatch to raw select within the custom element
            // bubble it up to allow change handler on custom element
            var notice = new Event('change', {bubbles: true});
            $(el)[0].dispatchEvent(notice);
        });
        $(this.element).find('span.select2').css('width', '100%');

        if (this.isMultiple === true) {
            this.selected = [];
            this.bindingEngine.collectionObserver(this.selected).subscribe(this.selectedArrayChanged.bind(this));
        }
    }

    public detached()
    {
        this.logger.debug('Detached');
        $(this.element).find('select').select2('destroy');
    }

    public selectedChanged(newValue:any, oldValue:any)
    {
        this.logger.debug('selectedChanged', newValue, oldValue);
        if (this.isMultiple === false) {
            let collection: any = this.selectElement.children;
            for (let i = 0; i < collection.length; i++) {
                if (collection[i].value === newValue) {
                    this.selectElement.selectedIndex = i;
                    break;
                }
            }
        }

        if (this.name === 'user-provinceid' && newValue !== undefined  && newValue !== null && newValue !== '') {
            let url = this.baseurl + '/provinces/' + newValue + '/prevotes';
            $.ajax({
                url: url,
                dataType: 'json',
                type: 'get',
            }).done((data) => {
                let prevoteSelect = $('#prevoteid-select2');
                let newData = [];

                prevoteSelect.empty();
                for (let i = 0; i < data.length; i++) {
                    newData.push({id:data[i].id, text:data[i].name, selected:false});
                }

                prevoteSelect.select2({
                    data:newData,
                    placeholder:'Sélectionnez une prévôté',
                    "language": {
                        "noResults": function(){
                            return "Aucun résultat trouvé";
                        }
                    },
                    escapeMarkup: function (markup) {
                        return markup;
                    }
                });
                if (oldValue === undefined) {
                    let prevoteIdHidden = $('.init-prevote-id').val();
                    prevoteSelect.val(prevoteIdHidden).trigger('change');
                } else {
                    prevoteSelect.val(null).trigger('change');
                    this.clearSelectedPrevote();
                }
                $('.select2-container').css('width', '100%');
            });
        } else if (this.name === 'user-provinceid') {

        }
    }

    public clearSelectedPrevote()
    {
        let selectPrevoteElement = <HTMLSelectElement>document.getElementById('user-prevoteid');
        let collection: any = selectPrevoteElement.children;
        for (let i = 0; i < collection.length; i++) {
            selectPrevoteElement.selectedIndex = null;
        }
    }

    public selectedArrayChanged(values:Array<ICollectionObserverSplice<any>>)
    {
        this.logger.debug('selectedArrayChanged', values);
    }
}

export {Select2CustomElement, Option}