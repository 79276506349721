import {LogManager, inject, bindable, noView, observable} from 'aurelia-framework';
import {ComponentAttached, ComponentDetached, TemplatingEngine} from 'aurelia-templating';
import {Logger} from 'aurelia-logging';
import {DOM} from 'aurelia-pal';
import {HttpClient, json} from "aurelia-fetch-client";
import {Option} from "./Select2CustomElement";

class User
{
    public nom:string = '';
    public prenom:string = '';
    public dateNaissance:string = '';
    public cotisationsPayees:boolean = false;
    public metiers:any[] = [];
    public chambres:any[] = [];
    public provinces:any[] = [];
    public vieux:boolean = false;
}

class BoUser
{
    public nom:string = '';
    public prenom:string = '';
    public dateNaissance:string = '';
    public tresorier:boolean = false;
    public tresorierProvince:boolean = false;
    public delegue:boolean = false;
    public provincial:boolean = false;
    public conseiller:boolean = false;
    public metiers:any[] = [];
    public userId:string = '';
}

class BoUpdateUser
{
    public id:string = '';
    public metiers:any[] = [];
    public tresorier:boolean = false;
}

@noView()
@inject(DOM.Element, TemplatingEngine)
class SearchFormCustomElement implements ComponentAttached, ComponentDetached
{
    private logger:Logger = LogManager.getLogger('SearchFormCustomElement');
    private httpClient:HttpClient;
    private templatingEngine: TemplatingEngine;

    private element:Element;
    private formElement:HTMLFormElement;
    private resultElement:HTMLDivElement;
    @bindable({attribute: 'form-name'}) public formName:string;
    @bindable({attribute: 'result-name'}) public resultName:string;
    public pageNumber:number = 0;
    public isLoading:boolean = false;
    public user:User = new User();
    public boUser:BoUser = new BoUser();
    public boUpdateUser:BoUpdateUser = new BoUpdateUser();
    public metiers:Option[];
    public chambres:Option[];
    public index:number;

    private excelUrl:Location;
    private wsUrl:string;
    private deleteFileUrl:string;


    public constructor(element:Element, templatingEngine:TemplatingEngine)
    {
        this.logger.debug('Construct');
        this.element = element;
        this.httpClient = new HttpClient();
        this.httpClient.configure(config => {
            config
                .useStandardConfiguration()
                .withDefaults({
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
            ;
        });
        this.templatingEngine = templatingEngine;
    }

    public attached()
    {
        this.logger.debug('Attached');
        this.formElement = <HTMLFormElement>document.getElementById(this.formName);
        this.templatingEngine.enhance({
            element: this.formElement,
            bindingContext: this
        });
        this.resultElement = <HTMLDivElement>document.getElementById(this.resultName);
    }

    public detached()
    {
        this.logger.debug('Detached');
    }

    public onSubmit2()
    {
        this.logger.debug('onSecondSubmit', this.user.metiers);
        let button = <HTMLButtonElement>document.getElementById('export-form-button');
        button.innerHTML = 'Génération du fichier excel...';
        if (button.className.indexOf('b4') !== -1) {
            button.className = button.className.replace('b4', 'b10');
        } else {
            button.className = button.className.replace('b11', 'b10');
        }

        let body:any = {
            User: Object.assign({}, this.user),
        };

        this.isLoading = true;
        this.httpClient.fetch('excel/export', {
            method: 'post',
            body: json(body)
        })
            .then(response => response.text())
            .then((value:any) => {
                let data:any  = JSON.parse(value);

                this.wsUrl = data['wsUrl'];
                this.excelUrl= data['excelUrl'];
                this.deleteFileUrl = data['deleteFileUrl'];
                this.callWs(button);
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    };

    public callWs(button:HTMLButtonElement)
    {
        this.httpClient.fetch(this.wsUrl, {
            method: 'get',
        })
            .then(response => response.text())
            .then((value:any) => {
                if (value == "false") {
                    setTimeout(() => {
                        this.callWs(button);
                    }, 1000);
                } else {
                    button.innerHTML = 'Fichier généré';
                    button.className = button.className.replace('b10', 'b11');
                    window.location = this.excelUrl;
                }
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    }

    public onSubmit(value:string = '')
    {
        if (value == 'submit') {
            this.pageNumber = 0;
        }
        this.logger.debug('onSubmit', this.user.metiers);
        let body:any = {
            User: Object.assign({}, this.user),
            page: this.pageNumber
        };

        this.isLoading = true;
        this.httpClient.fetch(this.formElement.action, {
            method: 'post',
            body: json(body)
        })
            .then(response => response.text())
            .then((value:any) => {
                this.resultElement.innerHTML = value;
                this.templatingEngine.enhance({
                    element: this.resultElement,
                    bindingContext: this,
                });
                this.isLoading = false;
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    };

    public onAdminSubmit(value:string = '')
    {
        if (value == 'submit') {
            this.pageNumber = 0;
        }

        this.logger.debug('onAdminSubmit', value);
        let body:any = {
            User: Object.assign({}, this.boUser),
            page: this.pageNumber
        };
        let span = <HTMLSpanElement>document.getElementById('msg-update');
        span.innerText = '';
        this.isLoading = true;
        this.httpClient.fetch(this.formElement.action, {
            method: 'post',
            body: json(body)
        })
            .then(response => response.text())
            .then((value:any) => {
                this.resultElement.innerHTML = value;
                this.templatingEngine.enhance({
                    element: this.resultElement,
                    bindingContext: this
                });
                this.isLoading = false;
            })
            .catch((reason:any) => {
                this.isLoading = false;
            });
    };

    public prev()
    {
        this.pageNumber -= 1;
        this.onSubmit();
    }

    public next()
    {
        this.pageNumber += 1;
        this.onSubmit();
    }

    public boPrev()
    {
        this.pageNumber -= 1;
        this.onAdminSubmit();
    }

    public boNext()
    {
        this.pageNumber += 1;
        this.onAdminSubmit();
    }

    public onToggleRow(event:Event)
    {
        this.logger.debug('onToggleRow');
        let target:Element = <Element>event.currentTarget;
        if (target.className.indexOf('inactive') !== -1) {
            target.className = target.className.replace('inactive', 'active');
        } else if(target.className.indexOf('active') !== -1) {
            target.className = target.className.replace('active', 'inactive');
        }
    }

    public onBoToggleRow(event:Event)
    {
        this.logger.debug('onBoToggleRow');
        let target:Element = <Element>event.srcElement.parentElement.parentElement;
        if (target.className.indexOf('inactive') !== -1) {
            target.className = target.className.replace('inactive', 'active');
        } else if(target.className.indexOf('active') !== -1) {
            target.className = target.className.replace('active', 'inactive');
        }
    }
}

export {SearchFormCustomElement}